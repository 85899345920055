<template>
  <main>
    <h3>Welcome to Round Here</h3>
    <p>
      Thanks for signing up for the Round Here Fund Raising Portal. You should
      have received an email with a link to validate your email address.
    </p>
    <p>
      You can
      <router-link :to="{ name: 'Login' }" class=" ms-1">sign in</router-link>
      now to get started.
    </p>
  </main>
</template>

<script>
export default {};
</script>

<style>
</style>